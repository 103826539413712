div.ticket-chat-messages {
  // background: #c49cdc;
  display: flex;
  flex-direction: column-reverse;

  min-height: calc(100% - 200px);
  overflow-y: auto;
  padding: 10px;
  // transition: 0.3s;

  div.ticket-chat-message:not(:last-child) {
    margin-bottom: 20px;
  }

  div.ticket-chat-message:first-child {
    margin-top: auto !important;
    padding-top: 10px !important;
  }

  div.infinite-scroll-component__outerdiv {
    margin-top: auto !important;
    padding-top: 10px !important;
  }

  div.ticket-chat-message {
    display: flex;
    flex-direction: column;

    span.ticket-chat-message-info {
      display: flex;
      align-items: center;
      margin-bottom: 5px;

      span.ticket-chat-message-info-name {
        font-weight: 500;
        color: #fff;
        border-radius: 5px;
        padding: 0px 6px;
        font-size: 15px;
        cursor: default;
      }

      span.ticket-chat-message-info-type {
        display: flex;

        svg {
          size: 25px;
          width: 25px;
          height: 25px;
          stroke-width: 1.5;
        }
      }
    }

    div.ticket-chat-message-container {
      display: flex;
      flex-direction: column;
      min-width: 100px;
      position: relative;
      gap: 3px;

      div.ticket-chat-message-container-block {
        display: flex;
        flex-direction: column;
        padding: 10px 10px 5px 10px;
        border-radius: 5px;
        transition: background-color 0.5s ease;
      }

      div.ticket-chat-message-options {
        position: absolute;
        right: 0px;
        top: 0px;

        span.ticket-chat-message-options-icon {
          filter: drop-shadow(0px 0px 7px black);
          display: flex;
          width: 24px;
          height: 24px;
          overflow: hidden;

          svg {
            color: #717171;
            transform: translate(25px, 0px);
            transition: 0.1s;
            cursor: pointer;
          }
        }
      }

      div.ticket-chat-message-content {
        word-wrap: break-word;
        word-break: break-word;
        white-space: normal;
      }

      div.ticket-chat-message-content > *:not(ol):not(ul) {
        margin: 0;
        padding: 0;
        font-size: 15px;
        width: 100%;
      }

      span.ticket-chat-message-date {
        display: flex;
        align-self: flex-end;
        align-items: center;
        font-size: 13px;
        cursor: default;
        margin-top: 5px;

        span.ticket-chat-message-status {
          display: flex;
          margin-left: 3px;
          margin-right: -5px;

          svg {
            size: 18px;
            width: 18px;
            height: 18px;
            stroke-width: 2;
          }
        }
      }

      div.ticket-chat-message-attachment-sending {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 5px;
        background-color: rgba(0, 0, 0, 0.0588235294);
        padding: 5px;
        border-radius: 5px;

        span.ticket-chat-message-attachment-sending-mock {
          background-color: #fff;
          border: 1px solid #c0c0c0;
          border-radius: 5px;
          padding: 10px;
          height: 78px;
          width: 78px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg.icon-sent-error {
            height: 40px;
            width: 40px;
            min-width: 40px;
            color: #bf5959;
            stroke: #bf5959;
          }

          svg.icon-tabler-cloud-upload {
            height: 78px;
            width: 78px;
            min-width: 78px;
            color: #4a69bd;
            stroke: #4a69bd;
          }

          .customIconUploadArrow {
            animation: moveArrow 1.5s infinite;
          }
    
          @keyframes moveArrow {
            0% { transform: translateY(0); }
            50% { transform: translateY(-3px); }
            100% { transform: translateY(0); }
          }
        }
      }

      div.ticket-chat-message-interactions {
        display: flex;
        gap: 3px;
        flex-wrap: wrap;
      
        span.ticket-chat-message-interactions-button {
          // background-color: #E5ECFF;
          border-radius: 5px;
          flex: 1 1 calc(50% - 10px);
          box-sizing: border-box;
          display: flex;
          align-items: center;
          justify-content: center;
          // min-width: 50px;
          cursor: default;
          padding: 5px 6px;
          font-size: 14px;
          line-height: 1.3;
          text-align: center;
          font-weight: 500;
        }
      
        span.ticket-chat-message-interactions-btn:nth-child(2n+1):nth-last-child(1) {
          flex: 1 1 100%;
        }
      }

      div.ticket-chat-message-reply-container {
        background-color: #ccd9fb;
        border-radius: 5px;
        border-left: 4px solid #4a69bd;
        padding: 4px 6px;
        font-size: 15px;
        transition: 0.3s;
        margin-bottom: 6px;

        span.ticket-chat-message-reply-info {
          span.ticket-chat-message-reply-info-name {
            font-weight: 500;
            color: #4a69bd;
          }
        }

        div.ticket-chat-message-reply-content {
          word-wrap: break-word;
          word-break: break-word;
          white-space: normal;
          font-size: 14px;

          svg {
            color: #4a69bd;
            width: 18px;
            height: 18px;
            margin-right: 5px;
          }

          span {
            color: #4a69bd;
          }
        }
      }

      div.ticket-chat-message-reply-container:hover {
        background-color: #bfcef3 !important;
        cursor: pointer;
      }
    }

    div.ticket-chat-message-container.ticket-chat-message-options-clicked,
    div.ticket-chat-message-container:hover {
      div.ticket-chat-message-options {
        span.ticket-chat-message-options-icon {
          svg {
            transform: translate(0px, 0px);
          }
        }
      }
    }
  }

  div.ticket-chat-message.chat-message-system {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 20px;
    margin-bottom: 20px;
    // z-index: -1;

    span.ticket-chat-line-system {
      width: 100%;
      background: #efefef;
      height: 2px;
      border-radius: 25px;
      margin-left: 10px;
      margin-right: 10px;
      transition: 0.3s;
    }

    span.ticket-chat-value-system {
      display: flex;
      align-items: center;
      position: absolute;
      background: #efefef;
      padding: 0px 10px;
      border-radius: 25px;
      color: #969696;
      font-size: 13px;
      font-weight: 500;
      transition: 0.3s;
      cursor: default;
      user-select: none;
    }
  }

  div.ticket-chat-message.chat-message-requester {
    align-items: flex-start;

    span.ticket-chat-message-info {
      flex-direction: row-reverse;

      span.ticket-chat-message-info-name {
        background: #707070;
      }

      span.ticket-chat-message-info-type {
        svg {
          color: #707070;
          stroke: #707070;
        }
      }
    }

    div.ticket-chat-message-container {
      // background: #f5f5f5;
      margin-left: 25px;

      div.ticket-chat-message-container-block {
        background-color: #f5f5f5 !important;

        div.ticket-chat-message-reply-container {
          background-color: #dbdbdb;
          border-left: 4px solid #707070;
  
          span.ticket-chat-message-reply-info {
            span.ticket-chat-message-reply-info-name {
              font-weight: 500;
              color: #707070;
            }
          }
  
          div.ticket-chat-message-reply-content {
            svg {
              color: #707070;
            }
  
            span {
              color: #707070;
            }
          }
        }
  
        div.ticket-chat-message-reply-container:hover {
          background-color: #d4d4d4 !important;
        }
      }
    
      div.ticket-chat-message-interactions {
        span.ticket-chat-message-interactions-button {
          background-color: #f5f5f5 !important;
        }
      }
    }
  }

  div.ticket-chat-message.chat-message-agent,
  div.ticket-chat-message.chat-message-bot {
    align-items: flex-end;

    span.ticket-chat-message-info {
      span.ticket-chat-message-info-name {
        background: #082598;
      }

      span.ticket-chat-message-info-type {
        svg {
          color: #082598;
          stroke: #082598;
        }
      }
    }

    div.ticket-chat-message-container {
      // background: #E5ECFF;
      margin-right: 25px;

      div.ticket-chat-message-container-block {
        background-color: #E5ECFF;
      }
    
      div.ticket-chat-message-interactions {
        span.ticket-chat-message-interactions-button {
          background-color: #E5ECFF;
        }
      }
    }    
  }

  div.ticket-chat-message.chat-message-internal {
    align-items: flex-end;

    span.ticket-chat-message-info {
      span.ticket-chat-message-info-name {
        background: #c07232;
      }

      span.ticket-chat-message-info-type {
        svg {
          color: #c07232;
          stroke: #c07232;
        }
      }
    }

    div.ticket-chat-message-container {
      // background: #ffe8d5;
      margin-right: 25px;

      div.ticket-chat-message-container-block {
        background-color: #ffe8d5 !important;
      }
    
      div.ticket-chat-message-interactions {
        span.ticket-chat-message-interactions-button {
          background-color: #ffe8d5 !important;
        }
      }
    }   
  }

  div.ticket-chat-message.chat-message-not-sent {
    span.ticket-chat-message-info {

      span.ticket-chat-message-info-name {
        background: #bf5959 !important;
      }

      span.ticket-chat-message-info-type {
        svg {
          color: #bf5959 !important;
          stroke: #bf5959 !important;
        }
      }
    }

    div.ticket-chat-message-container {
      // background: #d87878 !important;

      div.ticket-chat-message-content {
        color: #fff;
      }
      
      span.ticket-chat-message-date {
        color: #fff;
      }

      div.ticket-chat-message-container-block {
        background-color: #d87878 !important;

        div.ticket-chat-message-reply-container {
          background-color: #fdc8c8;
          border-left: 4px solid #bf5959;
  
          span.ticket-chat-message-reply-info {
            span.ticket-chat-message-reply-info-name {
              font-weight: 500;
              color: #bf5959;
            }
          }
  
          div.ticket-chat-message-reply-content {
            svg {
              color: #bf5959;
            }
  
            span {
              color: #bf5959;
            }
          }
        }
  
        div.ticket-chat-message-reply-container:hover {
          background-color: #f3b8b8 !important;
        }
      }
    
      div.ticket-chat-message-interactions {
        span.ticket-chat-message-interactions-button {
          background-color: #d87878 !important;
          color: #fff;
        }
      }
    }
  }
}

div.ticket-chat-messages.ticket-chat-messages-empty {
  height: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  svg.tabler-icon-messages {
    color: #4a69bd;
    stroke: #4a69bd;
    stroke-width: 1;
    // size: 30px !important;
    height: 80px;
    width: 80px;
  }

  span.ticket-chat-messages-info {
    color: #4a69bd;
    font-family: Montserrat;
    text-align: center;
    cursor: default;
  }
}

.editor {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_19-font-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  /* max-height: 9vh; */
  min-height: 6vh;
  width: 100%;
  word-break: break-all; 
  display: 'block';
  padding-left: 12px !important;
  resize: none;
}

.buttonBlueSendMessage {
  border-radius: 4px!important;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 100%;
  height: min(max(30.4px, 3.8vh), 41px) !important;
  border: none !important;
  font: var(--px15_19-font) !important;
}
div.chat-editor-reply-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 10px;
  background-color: #e3e3e3;
  border-radius: 5px;
  gap: 10px;
  overflow: hidden;
}

div.chat-editor-reply-info div.chat-editor-reply-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #c8c8c8;
  border-left: 5px solid #4a69bd;
  border-radius: 5px;
  padding: 4px;
  padding-left: 10px;
  overflow: hidden;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-author {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: -4px;
  user-select: none;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-content {
  font-size: 14px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-content svg {
  width: 18px;
  height: 18px;
  color: #4a69bd;
  margin-top: -3px;
  margin-right: 4px;
}

div.chat-editor-reply-info span.chat-editor-reply-cancel {
  cursor: pointer;
  color: #707070;
}
/*Remove margin bottom in chat messages*/
.chatMessageBox {
    /* background-color: red !important; */
    margin-bottom: 0px !important;
}

/* Estilo para o container da mensagem de chat */
div.iframe-chat-message-container {
    flex-direction: column;
    border-radius: 5px;
    position: absolute;
}

.iframe-chat-message-container {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 10;
}

div.iframe-chat-message-container div.iframe-chat-message-options span.iframe-chat-message-options-icon {
    filter: drop-shadow(0px 0px 7px black);
    display: flex;
    width: 24px;
    height: 24px;
    overflow: hidden;

}

div.iframe-chat-message-container div.iframe-chat-message-options span.iframe-chat-message-options-icon svg {
    color: black;
    transform: translate(25px, 0px);
    transition: 0.1s;
    cursor: pointer;
}

div.iframe-chat-message-container div.iframe-chat-message-content {
    word-wrap: break-word;
    word-break: break-word;
    white-space: normal;
}

div.iframe-chat-message-container div.iframe-chat-message-content>*:not(ol):not(ul) {
    margin: 0;
    padding: 0;
    font-size: 15px;
    width: 100%;
}

div.iframe-chat-message-container span.iframe-chat-message-date {
    display: flex;
    align-self: flex-end;
    align-items: center;
    font-size: 13px;
    cursor: default;
    margin-top: 5px;
}


div.iframe-chat-message-container span.iframe-chat-message-date span.iframe-chat-message-status {
    display: flex;
    margin-left: 3px;
    margin-right: -5px;
}


div.iframe-chat-message-container span.iframe-chat-message-date span.iframe-chat-message-status svg {
    size: 18px;
    width: 18px;
    height: 18px;
    stroke-width: 2;
}


div.iframe-chat-message-container div.iframe-chat-message-attachment-sending span.iframe-chat-message-attachment-sending-mock svg.icon-sent-error {
    height: 40px;
    width: 40px;
    min-width: 40px;
    color: #bf5959;
    stroke: #bf5959;
}

div.chat-editor-replyM-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 5px;
    padding: 10px;
    background-color: #e3e3e3;
    border-radius: 5px;
    gap: 10px;
    overflow: hidden;
}

div.chat-editor-reply-message {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #c8c8c8;
    border-left: 5px solid #4a69bd;
    border-radius: 5px;
    padding: 4px;
    padding-left: 10px;
    overflow: hidden;
}

span.chat-editor-reply-message-author {
    font-weight: 600;
    font-size: 15px;
    margin-bottom: -4px;
    user-select: none;
}

span.chat-editor-reply-message-content {
    font-size: 14px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    cursor: default;
}

span.chat-editor-reply-message-content svg {
    width: 18px;
    height: 18px;
    color: #4a69bd;
    margin-top: -3px;
    margin-right: 4px;
}
div.message-card-bot-container {
    position: relative;
    cursor: default;

    div.iframe-chat-message-container.iframe-chat-message-options-clicked {
        div.iframe-chat-message-options {
            span.iframe-chat-message-options-icon {
                svg {
                    transform: translate(0px, 0px);
                }
            }
        }
    }
}

div.message-card-bot-container:hover {
    div.iframe-chat-message-container {
        div.iframe-chat-message-options {
            span.iframe-chat-message-options-icon {
                svg {
                    transform: translate(0px, 0px);
                }
            }
        }
    }
}
.editor {
  border: none;
  cursor: text;
  overflow-y: auto;
  text-align: left;
  font: var(--px15_20-Roboto);
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  max-height: 5vh;
  min-height: 3vh;
  /* height: 3vh; */
  width: 100%;
  /* width: calc(100% - max(72px, 6vw)); */
  word-break: break-all; 
  display: 'block';
  padding-left: 12px !important;
  resize: none;
}

.buttonBlueSendMessage {
  border-radius: 4px !important;
  background: 0% 0% no-repeat padding-box;
  opacity: 1;
  /* width: 100%; */
  /* min-width: 40px;
  max-width: 72px; */
  height: max(23px, 4vh) !important;
  /* height: min(max(20px, 3vh), 30px) !important; */
  /* width: max(72px, 6vw); */
  /* height: 4.5vh; */
  border: none !important;
  font: var(--px17_24-font) !important;
}
.img-iframe {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.img-iframe:hover {
  transform: scale(1.05);
  opacity: 0.8;
}

.slideIn {
  animation: slideIn 1s forwards;
  overflow: hidden;
}

@keyframes slideIn {
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.slideOut {
  animation: slideOut 2s forwards;
  overflow: hidden;
}

@keyframes slideOut {
  from {
    transform: translateY(0);
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
}
div.chat-editor-reply-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px;
  padding: 10px;
  background-color: #e3e3e3;
  border-radius: 5px;
  gap: 10px;
  overflow: hidden;
}

div.chat-editor-reply-info div.chat-editor-reply-message {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #c8c8c8;
  border-left: 5px solid #4a69bd;
  border-radius: 5px;
  padding: 4px;
  padding-left: 10px;
  overflow: hidden;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-author {
  font-weight: 600;
  font-size: 12px;
  margin-bottom: -4px;
  user-select: none;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-content {
  font-size: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: default;
}

div.chat-editor-reply-info div.chat-editor-reply-message span.chat-editor-reply-message-content svg {
  width: 18px;
  height: 18px;
  color: #4a69bd;
  margin-top: -3px;
  margin-right: 4px;
}

div.chat-editor-reply-info span.chat-editor-reply-cancel {
  cursor: pointer;
  color: #707070;
}

body {
  background: transparent !important;
}